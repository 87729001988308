*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a.colorlessLink{
  text-decoration: none;
  color: unset;
}
ul.noListStyle{
  list-style: none;
}
button.noStyleButtons{
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: unset;
}
.clickable{
  cursor: pointer;
}
.root{
  position: relative;
}
::selection{
  background-color: var(--green);
  color: var(--white);
}
:root{
  /* colors */
  /* --black:#161a1b;
  --white:#ECEDED;
  --green:#2a898d; */
  --black: rgb(22, 26, 27);
  --blackOpac50: rgb(46, 49, 49);
  --white: rgb(236, 237, 237);
  --whiteOpac50: rgba(236, 237, 237, 0.5);
  --green: rgb(42, 137, 141);
  --greenOpac50: rgba(42, 138, 141, 0.5);

  /* font */
  --fontFamilyStandard: 'Montserrat', sans-serif;
  --fontWeightStandard: 400;
  --fontWeightSemiBold: 500;
  --fontWeightBold: 600;
  --fontSizeStandard: 1rem;
  --paddingStandard: 1rem;
  --paddingChild: 0.5rem;
  --marginStandard: 1rem;
  --marginChild: 0.5rem;
  --letterSpacingStandard: 1px;

  /* flex */
  --flexGap:5rem;
}
body{
  background-color: var(--black);
  font-family: var(--fontFamilyStandard);
  color: var(--white);
  min-height: 100%;
  width: 100%;
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
}
body::-webkit-scrollbar:vertical {
  width: 8px;
}
body::-webkit-scrollbar:horizontal {
  height: 8px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid var(--green);
  background-color: var(--black);
}
body::-webkit-scrollbar-track { 
  background-color: var(--black);
  mix-blend-mode:soft-light;
  z-index: -100;
  border-radius: 8px; 
} 

/* header */
header{
  padding: calc(var(--paddingChild) + var(--paddingStandard));
  color: var(--white);
  display: flex;
  align-items:center;
  position: sticky;
  top: 0;
  z-index: 100;
  min-height: max-content;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  background-color: rgba(22, 26, 27, 0.228);

  /* gap: calc(var(--flexGap)*1.2); */
}
  /* header > CrootiveLogo */
span.crootiveLogoContainer{
  padding: var(--paddingChild);
  font-size: calc(var(--fontSizeStandard)*2.05);
  font-weight: calc(var(--fontWeightBold) + 70);
  letter-spacing: var(--letterSpacingStandard);
  margin-right: 6.5vw;
}
span.crootiveColorLetter{
  color: var(--green);
  opacity: 100%;
}
  /* header > Nav */
header nav ul.navigationList{
  display: flex;
  align-items: center;
}
header nav ul.navigationList li button{
  font-family: var(--fontFamilyStandard);
  font-weight: calc(var(--fontWeightStandard)*1.2);
  font-size: calc(var(--fontSizeStandard)*1.05);
  margin: var(--marginStandard);
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease;
}
header nav ul.navigationList li.active{
  border-bottom: 3px solid var(--green);
}


label.navigationListHamburger{
  display: none;
}
label .menu {
  position: absolute;
  right: 25px;
  top: 22.5px;
  width: 53px;
  height: 49px;
  border-radius: 50% 50% 50% 50%;
  transition: .5s ease-in-out;
  cursor: pointer;
  border-radius: 10%;
}
label .hamburger {
  position: absolute;
  top: 24px;
  right: 11px;
  width: 30px;
  height: 2px;
  background: var(--white);
  display: block;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}
label .hamburger:after, label .hamburger:before {
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: var(--white);
}
label .hamburger:before { top: -10px; }
label .hamburger:after { bottom: -10px;}
label input { display: none;}
label input:checked + .menu {
  box-shadow: 0 0 0 100vw var(--grey2), 0 0 0 100vh var(--grey2);
}
label input:checked + .menu .hamburger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: var(--black);

}
label input:checked + .menu .hamburger:after {
  -webkit-transform: rotate(90deg);
  background-color: var(--black);
  transform: rotate(90deg);
  bottom: 0;

}
label input:checked + .menu .hamburger:before {
  -webkit-transform: rotate(90deg);
  background-color: var(--black);
  transform: rotate(90deg);
  top: 0;
}
@media (max-width:520px) {
  ::root{
    --flexGap:10rem;
  }
  header nav ul.navigationList{
    display: none;
  }
  header nav div.menu{
    display: flex;
  }
  label.navigationListHamburger{
    display: unset !important;
  }
}





/* footer */

.footer{
  display: flex;
  flex-direction: column;
  padding: var(--paddingChild);
  transition: all 0.3s ease;
  border-top: 1px solid var(--white);
  background-color:  rgb(44, 43, 43);
  /* position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -100; */
}
.footerBottom{
  display: flex;
  justify-content:space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-block: 2vh;
  text-align: center;
  gap:1rem;
  color: var(--white);
  margin-inline: 2vw;
}
.rulesLinks{
  display: flex;
  flex-wrap: wrap;
  gap:1.5vw;
  justify-content:space-evenly;
}
.footerTop{
  display: flex;
  width: 92%;
  background-color: var(--grey2);
  padding: 1.5rem;
  justify-content: space-around;
  flex-wrap: wrap;
  gap:1.5rem;
  align-self: center;
}
.brandFooter{
  font-family: var(--textFont);
  color: var(--colorWhite);
}
.footerLiItems{
  display: flex;
  flex-direction:column;
  gap:0.5rem;
  margin-block: 1.3rem;
  color: var(--colorWhite);
}
.footerLiItems > *{
  cursor:pointer;
}
.footerDet > h3{
  color: var(--colorWhite);
}
.footerDet{
  margin-top: 3.3rem;
}
.footerDet:nth-child(1){
  margin-top: unset;
}


@media(max-width: 1024px) {
  html{
    font-size: 95%;
  }
}
@media(max-width: 768px) {
  html{
    font-size: 90%;
  }
}
@media(max-width: 375px) {
  html{
    font-size: 75%;
  }
  label .menu {
    transform: scale(0.9);
    top: 15px;
  }
}
@media(max-width: 300px) {
  html{
    font-size: 68%;
  }
  label .menu {
    transform: scale(0.8);
    top: 12px;
  }
}