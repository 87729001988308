.blurSvgContainer {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: -2;
    overflow: hidden;
}
.heroImage {
    position: absolute;
    top: 1%;
    right: 2vw;
    height: 40rem;
    width: 40rem;
    min-width: 18rem;
    border-radius: 0.3rem;
    z-index: -1;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
}
.heroImage svg {
    width: inherit;
    height: inherit;
    min-width: 18rem;
    border-radius: 0.3rem;
    animation: animSvg 120s ease;
}
@keyframes animSvg {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.blurSvgContainer svg {
    filter: blur(100px);
    opacity: 50%;
    animation: svgBlur 120s ease-in-out infinite alternate;
}
@keyframes svgBlur {
    0% {
        transform: rotate(0deg) translate(30%, 0%);
    }
    100% {
        transform: rotate(270deg) translate(60%, 40%);
    }
}
.bodyContent {
    --maxWithHome: 59vw;
    background-color: transparent;
}
.setGreen {
    color: var(--green);
}
.crootiveName {
    text-decoration: underline;
}
div.section {
    padding: calc(var(--paddingStandard) * 2);
    margin-inline: calc(var(--marginStandard) * 2);
    /* border: 1px solid var(--green); */
}
div.heroSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: calc(var(--flexGap)*1.2); */
}
aside.heroAside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: calc(var(--flexGap) * 1.9);
    width: 7%;
    min-width: 3.5rem;
    height: 32rem;
    margin-left: 1.2rem;
    margin-right: 6.2vw;
    width: 7rem;
}
.heroAside svg {
    min-width: 5rem;
    max-width: 7rem;
    min-height: 5rem;
    max-height: 7rem;
    transform: translateX(-16%);
}
div.heroMain h1 {
    margin-left: 0.5rem;
    max-width: 30rem;
    font-size: calc(var(--fontSizeStandard) * 3.8);
    margin-bottom: 1.1rem;
}
.heroMainHead {
    font-size: calc(var(--fontSizeStandard) * 7);
}

/* Case Studies */
div.sectionCaseStudies {
    display: flex;
    flex-direction: column;
    gap: calc(var(--flexGap) * 0.6);
}
aside.csAside {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: calc(var(--flexGap)*.7); */
}
.csAside p {
    text-align: center;
    width: 7rem;
    margin-right: 7.5vw;
    font-weight: var(--fontWeightBold);
}
.csAside h2 {
    padding: var(--paddingChild);
    font-size: calc(var(--fontSizeStandard) * 3.2);
}
div.csCarasol {
    display: flex;
    overflow-x: scroll;
    position: relative;
    gap: var(--flexGap);
    padding-inline: 22rem 19rem;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    transition: all 0.5s ease-in-out;
    /* margin-left: 7.5vw;
    padding-left: calc(var(--paddingChild) + 7rem); */
}
.arrows {
    margin-block: auto;
    background-color: rgba(71, 71, 71, 0.548);
    position: absolute;
    z-index: 10;
}
/* .arrow2{
    left: -14.5%;
    top: 40%;
    border-radius: 0 0.5rem 0.5rem 0;
}
.arrow1{
    right: -36%;
    top: 40%;
    border-radius: 0.5rem 0 0 0.5rem ;
} */
.arrows p {
    color: var(--white);
    width: 3.5rem;
    line-height: 3.5rem;
    padding-inline: 0.5rem;
    font-size: calc(var(--fontSizeStandard) * 4.5);
    height: 3.5rem;
}
div.csCarasol::-webkit-scrollbar {
    display: none;
}
div.csCarasolItem {
    display: flex;
    flex-direction: column;
    scroll-snap-align: center;
    scroll-snap-stop: always;
}
div.csCarasolItem img {
    width: var(--maxWithHome);
    aspect-ratio: 18/10;
    object-position: center;
    object-fit: cover;
    border-radius: 0.2rem;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}
.csImgContainer:hover img{
    transform: scale(0.995);
}
.csImgContainer:hover .linkOverlay{
    transform: scale(0.995);
    color: var(--white);
    background-color:rgba(0, 0, 0, 0.439);
}
.csImgContainer {
    position: relative;
}
.csImgContainer:hover .linkArrow {
    transform: scale(2.8);
    color: var(--white);
}
svg.linkArrow {
    z-index: 1;
    position: absolute;
    right: 3%;
    top: 5%;
    transform: scale(2.5);
    transition: all 0.3s ease;
    color: var(--blackOpac50);
}
.linkOverlay{
    position: absolute;
    left: 0%;
    background-color:rgba(0, 0, 0, 0.204);
    transition: all 0.3s ease;
    height: 99.2%;
    width: 100%;
    color: var(--black);
}
.linkOverlay p{
    position: absolute;
    bottom: 7%;
    left: 3%;
    font-weight: var(--fontWeightSemiBold);
    transition: all 0.3s ease;
}
div.csCarasolItem div.itemInfo {
    padding-block: 1rem;
}
p.fadeItemText {
    opacity: 40%;
    font-weight: var(--fontWeightSemiBold);
}
.itemInfo h3 {
    font-size: calc(var(--fontSizeStandard) * 1.4);
    margin-block: var(--marginChild);
}

/* Our Capabilities */
aside.ocAside {
    align-items: center;
    overflow: hidden;
    margin-block: calc(var(--marginChild) * 5);
}
aside.ocAside p {
    font-size: calc(var(--fontSizeStandard) * 4);
    color: var(--green);
    font-weight: 100;
    transform: translateY(80%);
    line-height: 1rem;
}
ul.ocList {
    display: flex;
    flex-direction: column;
    margin-block: calc(var(--marginChild) * 8);
    margin-inline: auto;
    max-width: var(--maxWithHome);
    min-width: 18rem;
    /* margin-left: 7.5vw;
    padding-left: calc(var(--paddingChild) + 7rem); */
}
ul.ocList li > .ocHead {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(var(--fontSizeStandard) * 2);
    padding-block: calc(var(--paddingStandard) * 2);
    font-weight: var(--fontWeightSemiBold);
    border-top: 2px solid var(--greenOpac50);
}
ul.ocList li {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
ul.ocList li:nth-last-child(1) {
    border-bottom: 2px solid var(--greenOpac50);
}
ul.ocList li > .ocHead svg {
    min-width: 1rem;
    transition: all 0.2s ease-in-out;
}
ul.ocList li:hover > .ocHead svg,
ul.ocList li.liOpen > .ocHead svg {
    stroke: var(--green);
    transform: rotate(90deg);
}
ul.ocList li > .ocDescription {
    position: relative;
    /* padding-block: calc(var(--paddingStandard)*2); */
    font-size: calc(var(--fontSizeStandard) * 1.5);
    font-weight: var(--fontWeightStandard);
    height: 0;
    overflow: hidden;
    transition: 0.5s;
}
ul.ocList li.liOpen .ocDescription {
    padding-block: calc(var(--paddingStandard) * 2);
    height: 22vh;
}
/* ul.ocList li:hover{
    background-color: var(--blackOpac50);
 
} */

/* image */
.scrollBackgroundImage {
    background-color: var(--black);
    margin-inline: 0;
    padding-inline: 0;
    width: 100%;
    height: 20rem;
    margin-block: calc(var(--marginChild) * 5);
    overflow: hidden;
}

/* experience */
div.exInformation {
    display: flex;
    flex-direction: row;
    margin-inline: auto;
    /* flex-wrap: wrap; */
    max-width: calc(var(--maxWithHome));
    margin-block: calc(var(--marginChild) * 8);
    gap: calc(var(--flexGap) * 1);
    min-width: 16rem;

    /* margin-left: 7.5vw;
    padding-left: calc(var(--paddingChild) + 7rem); */
}
aside.exAside {
    margin-block: calc(var(--marginChild) * 5);
}
p.exYears {
    font-size: calc(var(--fontSizeStandard) * 6);
    line-height: 4.5rem;
    font-weight: calc(var(--fontWeightBold) + 50);
}
p.exYears span {
    font-size: calc(var(--fontSizeStandard) * 3);
}
p.exInfo {
    font-size: calc(var(--fontSizeStandard) * 1.6);
    font-weight: var(--fontWeightStandard);
    min-width: 18rem;
}

/* Get in thouch */
aside.gtAside {
    margin-block: calc(var(--marginChild) * 7);
}
aside.gtAside p {
    text-align: unset;
    padding-inline: var(--paddingChild);
    transform: translateX(7%);
}
form.gtForm {
    margin-inline: auto;
    margin-block: calc(var(--marginChild) * 14);
    max-width: var(--maxWithHome);
    /* margin-left: 7.5vw;
    padding-left: calc(var(--paddingChild) + 7rem); */
}
.gtForm div.gtInputsContainer {
    display: flex;
    flex-direction: column;
}
.input-container {
    position: relative;
    min-width: 15rem;
    margin-block: calc(var(--marginStandard) * 2);
}
.input-container .label-n {
    position: absolute;
    top: -8px;
    left: 0px;
    font-size: calc(var(--fontSizeStandard) * 1.5);
    color: var(--whiteOpac50);
    transition: all 0.5s ease-in-out;
}
.input-container input {
    border: 0;
    border-bottom: 2px solid var(--white);
    transition: all 0.5s ease;
    background: transparent;
    width: 100%;
    padding: 8px 1rem 5px 1rem;
    font-size: calc(var(--fontSizeStandard) * 1.3);
    color: var(--white);
    text-wrap: wrap;
}
.input-container input:focus {
    border: none;
    outline: none;
    border-bottom: 3px solid var(--green);
}
.input-container input:focus ~ .label-n,
.input-container input:valid ~ .label-n {
    top: -12px;
    font-size: 12px;
}
.sendButton {
    background: none;
    border: none;
    outline: none;
    color: var(--white);
    display: flex;
    align-items: center;
    gap: calc(var(--flexGap) * 0.2);
    font-size: calc(var(--fontSizeStandard) * 2);
    margin-left: auto;
}
.sendButton svg {
    transition: all 0.2s ease-in-out;
    transform: scale(1.5);
}
.sendButton:hover svg {
    stroke: var(--green);
    transform: scale(2);
}

.owContent {
    --maxWithWork: 59vw;
    margin-top: calc(var(--marginChild) * 8);
    margin-bottom: calc(var(--marginChild) * 16);
}
.owHeader {
    text-align: center;
    margin: calc(var(--marginStandard) * 2);
}
.owSection {
    padding: calc(var(--paddingStandard) * 2);
    margin-inline: calc(var(--marginStandard) * 2);
    font-size: calc(var(--fontSizeStandard) * 1.18);
    max-width: 75vw !important;
    min-width: 18rem;
    margin-inline: auto;
}
.sdList {
    padding-left: 2rem;
    list-style: inside;
}
.sdObjective {
    font-style: italic;
    text-decoration: underline;
    font-weight: var(--fontWeightSemiBold);
}
.owImages {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline: auto;
    max-width: var(--maxWithWork);
    justify-content: space-evenly;
    row-gap: calc(var(--flexGap) * 0.5);
    margin-block: calc(var(--marginChild) * 8);
    flex-wrap: wrap;
}
.owImages img {
    width: 25rem;
    border-radius: 0.2rem;
}




.inContent{
    --maxWithWork: 63vw;
    margin-top: calc(var(--marginChild) * 4);
    padding-top: calc(var(--marginChild) * 4);
    margin-bottom: calc(var(--marginChild) * 16);
}
.inCarasol h1{
    margin-block: calc(var(--marginStandard) * 2);
    font-size: calc(var(--fontSizeStandard) * 3.8);
}
.inCarasol ul::-webkit-scrollbar{
    display: none;
}
.inCarasol svg{
    width: 3rem;
    height: 3rem;
    margin-inline: 1rem;
    stroke: var(--green);
}
.inCard{
    display: flex;
    flex-direction: column;
    min-height: 15rem;
    min-width: 12rem;
    max-width: 15rem;
    gap: 2rem;
}
.carasoulMarqueeContainer{
    position: relative;
    overflow: scroll;
    width: 100%;
}::-webkit-scrollbar{
    display: none;
}
.carasoulContainer{
    display: flex;
    flex-direction: row;
    width: 200%;
    gap: calc(var(--flexGap) * 1);
    overflow: scroll;
}
.inList{
    display: flex;
    flex-direction: row;
    gap: calc(var(--flexGap) * 1);
    padding-inline: var(--paddingChild);
    animation: marqueAnim 70s infinite linear;
    cursor: pointer;
}
.carasoulContainer:hover .inList{
    animation-play-state: paused;
}
@keyframes marqueAnim {
    to{
        transform: translateX(-103.5%);
    }    
    from{
        transform: translateX(0%);
    }
}
/* .inList:nth-last-child(1){
    background-color: rgba(123, 123, 123, 0.31);
} */


@media screen and (min-width: 860px) {
    form.gtForm {
        margin-left: 7.5vw;
        padding-left: calc(var(--paddingChild) + 7rem);
    }
    div.exInformation {
        margin-left: 7.5vw;
        padding-left: calc(var(--paddingChild) + 7rem);
    }
    ul.ocList {
        margin-left: 7.5vw;
        padding-left: calc(var(--paddingChild) + 7rem);
    }
    div.csCarasol {
        margin-left: 7.5vw;
        padding-left: calc(var(--paddingChild) + 7rem);
    }
    .owSection {
        margin-left: 7.7vw;
        padding-left: calc(var(--paddingChild) + 11rem);
        max-width: var(--maxWithWork);
    }
    .inCarasol{
        margin-left: 7.7vw;
        padding-left: calc(var(--paddingChild) + 11rem);
        width: var(--maxWithWork);
    }
}
@media screen and (max-width: 860px) {
    div.heroMain h1 {
        font-size: calc(var(--fontSizeStandard) * 3.5);
    }
    .heroMainHead {
        font-size: calc(var(--fontSizeStandard) * 6);
    }
    .heroImage {
        display: none;
    }
    div.csCarasolItem img {
        width: 30rem;
    }
    div.csCarasol {
        padding-inline: 10rem;
    }
    .csAside p {
        width: 8rem;
        /* margin-right: 2rem; */
        margin-right: 7vw;
    }
    div.exInformation {
        flex-wrap: wrap;
    }
    p.exYears {
        font-size: calc(var(--fontSizeStandard) * 4);
        /* font-weight: calc(var(--fontWeightBold) + 50); */
    }
    p.exInfo {
        font-size: calc(var(--fontSizeStandard) * 1.5);
        font-weight: var(--fontWeightStandard);
        /* min-width: 20rem; */
    }
    .inCarasol{
        margin-inline: auto;
        max-width: var(--maxWithWork);
    }
}
@media screen and (max-width: 640px) {
    div.heroMain h1 {
        font-size: calc(var(--fontSizeStandard) * 2.3);
    }
    .heroMainHead {
        font-size: calc(var(--fontSizeStandard) * 4.7);
    }
    ul.ocList li.liOpen .ocDescription {
        height: 26vh;
    }
}
@media screen and (max-width: 565px) {
    div.heroSection {
        flex-direction: column-reverse;
        gap: calc(var(--flexGap) * 1);
    }
    aside.heroAside {
        width: 100%;
        height: 30%;
        gap: unset;
        flex-direction: row;
        align-items: center;
    }
    .heroAside svg {
        stroke-width: 1;
        transform: rotate(90deg);
    }
    div.sectionCaseStudies {
        align-items: center;
    }
    div.csCarasolItem img {
        width: 22rem;
    }
    /* div.csCarasolItem img:nth-child(1){
        margin-left: 10vw;
    } */
    div.csCarasol {
        width: 80vw;
    }
    .csAside p {
        width: 7rem;
        margin-right: 1rem;
    }
    .csAside h2 {
        font-size: calc(var(--fontSizeStandard) * 2);
    }
    ul.ocList li {
        font-size: calc(var(--fontSizeStandard) * 1.2);
    }
    ul.ocList li.liOpen .ocDescription {
        height: 30vh;
    }
}
@media screen and (max-width: 430px) {
    .heroMainHead {
        font-size: calc(var(--fontSizeStandard) * 4);
    }
    div.csCarasolItem img {
        width: 20rem;
    }
    div.csCarasol {
        padding-inline: 2rem;
    }
    .csAside p {
        width: 6rem;
        margin-right: 1rem;
    }
    .ocAside p {
        width: min-content;
    }
}
